import React from 'react';
import {
  useResetPasswordForm,
  ResetPasswordProvider
} from '@jetshop/core/components/Auth/ResetPasswordProvider';
import { LoginWrapper } from './UI/Form';
import InputWithLabel from '../Forms/Input';
import t from '@jetshop/intl';
import ButtonWithLoading from '../ui/Button';
import { GlobalError } from '../Forms/GlobalError';
import Head from '@jetshop/core/components/Head';
import { Link } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { ButtonUI } from '../ui/ButtonUI';

export default function ResetPasswordPage() {
  return (
    <ResetPasswordProvider>
      <ResetPassword />
    </ResetPasswordProvider>
  );
}

function ResetPassword(props) {
  const {
    globalError,
    passwordInputProps,
    isSubmitting,
    isValid,
    submitted
  } = useResetPasswordForm();
  const { routes } = useShopConfig();

  return (
    <LoginWrapper>
      <Head data={{ title: 'Reset Password' }} />

      <section>
        {submitted ? (
          <>
            <p>{t('Your password has been reset.')}</p>
            <p>{t('You can now login with your new password')}</p>
            <ButtonUI style={{ marginTop: '1rem' }}>
              <Link to={routes.login.path}>{t('Log in')}</Link>
            </ButtonUI>
          </>
        ) : (
          <>
            <InputWithLabel
              {...passwordInputProps}
              label={t('Please enter your new password')}
            />

            {globalError && (
              <GlobalError style={{ marginBottom: '1em' }}>
                {globalError}
              </GlobalError>
            )}

            <ButtonWithLoading
              loading={isSubmitting}
              type="submit"
              disabled={!isValid}
            >
              {t('Submit')}
            </ButtonWithLoading>
          </>
        )}
      </section>
    </LoginWrapper>
  );
}
