import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import t from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import React from 'react';
import Input, { Label } from '../../Forms/Input';
import { PID } from './PID';
import { CustomerType } from './CustomerType';
import { SmallCaps } from '../../ui/Headings';

export default function Address(props) {
  const { fields, setCountryByCode, countries } = useAddressFields();

  return (
    <section>
      <SmallCaps className="heading">{t('Contact Details')}</SmallCaps>

      <p>
        {t(
          'Please fill in your contact details for shipping and communication.'
        )}
      </p>

      <CustomerType />

      <div style={{ marginBottom: '2em' }}>
        <Label htmlFor="country">Country</Label>
        <CountriesInput
          name="country"
          onChange={e => setCountryByCode(e.currentTarget.value)}
          countries={countries}
        />
      </div>

      <PID />

      {fields.map(field => {
        // Map over billingAddressFields and display them
        return <Input {...field.inputProps} />;
      })}
    </section>
  );
}
