import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { ButtonUI } from '../../ui/ButtonUI';
import { useSignupValidation } from './useSignupValidation';

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const { isSignupError, validationDetails, isSubmitting } = useSignupForm();
  const validationMessage = useSignupValidation(validationDetails);

  return (
    <section>
      <SmallCaps className="heading">{t('Email & Password')}</SmallCaps>

      {isSignupError && (
        <GlobalError style={{ marginBottom: '2em' }}>
          {validationMessage}
        </GlobalError>
      )}

      {fields.map((field) => {
        return <Input {...field.inputProps} />;
      })}

      <ButtonUI
        data-testid="sign-up-button"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </ButtonUI>
    </section>
  );
}
