import {
  ForgotPasswordProvider,
  useForgotPasswordData
} from '@jetshop/core/components/Auth/ForgotPasswordProvider';
import Head from '@jetshop/core/components/Head';
import t from '@jetshop/intl';
import React from 'react';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import ButtonWithLoading from '../ui/Button';
import { LoginWrapper } from './UI/Form';

export default function ForgotPasswordPage(props) {
  return (
    <LoginWrapper>
      <ForgotPasswordProvider>
        <section>
          <h1>{t(`Forgot password`)}</h1>
          <Head data={{ title: 'Forgot Password' }} />
          <ForgotPasswordForm />
        </section>
      </ForgotPasswordProvider>
    </LoginWrapper>
  );
}

function ForgotPasswordForm() {
  const {
    submitted,
    isSubmitting,
    globalError,
    isValid,
    emailInputProps
  } = useForgotPasswordData();

  if (submitted) {
    return (
      <p style={{ lineHeight: 1.5 }}>
        {t(
          `Your password reset request has been submitted. Check your email for a link to reset your password.`
        )}
      </p>
    );
  }

  return (
    <>
      <Input label={t('What is your email address?')} {...emailInputProps} />

      {globalError && (
        <GlobalError style={{ marginBottom: '1em' }}>{globalError}</GlobalError>
      )}

      <ButtonWithLoading
        loading={isSubmitting}
        type="submit"
        disabled={!isValid}
      >
        {t('Submit')}
      </ButtonWithLoading>
    </>
  );
}
