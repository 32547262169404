import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { Redirect } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import Address from './Address';
import LoginFields from './LoginFields';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { theme } from '../../Theming/Theming';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  margin: 1rem 0 3rem;
  padding: 0 2rem;
  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    max-width: 100%;
  }

  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }
`;

const Section = styled('section')`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 55rem;
  margin-bottom: 3rem;
  > header {
    width: 100%;
    flex-grow: 1;
  }
  > div {
    width: 48%;
  }
  ${theme.below.md} {
    display: block;
    width: 80%;
    > div {
      width: 100%;
    }
  }
  ${theme.below.sm} {
    width: 100%;
  }
`;

function SignUpPage() {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  return (
    <SignupFormProvider LoadingComponent={<LoadingPage />}>
      <MaxWidth>
        <Intl>{t => <Head data={{ title: t('Sign up') }} />}</Intl>
        <Section>
          <header>
            <h1>{t('Sign up')}</h1>
            <p>
              {t(
                'Welcome to our online store! We need to create a profile before you continue.'
              )}
            </p>
          </header>
          <div>
            <Address />
          </div>
          <div>
            <LoginFields />
          </div>
        </Section>
      </MaxWidth>
    </SignupFormProvider>
  );
}

export default SignUpPage;
